import React from "react";
import './Error.css'; 

const Error = () => {
  return (
     <div>
    <div className="error-container">
      <div className="error-code">
        <h1>404</h1>
      </div>
      <div class="vertical-line"></div>
      <div className="error-message">
        <h2>Esta página não pode ser encontrada.</h2>
        <p>Você pode continuar relaxando por aqui, ou voltar para o site.</p>
        <a href="/" className="back-home-button">VOLTAR PARA HOME</a>
      </div>
    </div>
    </div>
  );
};

export default Error;