// src/pages/Home.js
import React from 'react';
import Form from '../components/contact/Form';

function Contact() {
  return (
    <div>
      <Form />
    </div>
  );
}

export default Contact;
