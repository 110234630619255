// TestimonialsSection.jsx

import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './TestimonialsSection.css';
import avatar from '../../assets/avatar.jpg'; // Substitua pelo caminho correto da sua imagem
import avatar1 from '../../assets/Pessoa_IA1.png';
import avatar2 from '../../assets/Pessoa_IA2.png';
import avatar3 from '../../assets/Pessoa_IA3.png';
import avatar4 from '../../assets/Pessoa_IA4.png';
import avatar5 from '../../assets/Pessoa_IA5.png';
import { FaStar, FaStarHalfAlt, FaRegStar, FaArrowLeft, FaArrowRight } from 'react-icons/fa';

function TestimonialsSection() {
  const testimonials = [
    {
      avatar: avatar4,
      name: "João Silva",
      location: "Conselheiro Lafaiete, MG",
      text: "O serviço de internet da VitaLink é excelente! Conexão estável e rápida, perfeito para o meu trabalho.",
      rating: 5.0
    },
    {
      avatar: avatar1,
      name: "Maria Oliveira",
      location: "Conselheiro Lafaiete, MG",
      text: "Atendimento impecável! Sempre que preciso, o suporte está disponível e resolve tudo rápido.",
      rating: 5.0
    },
    {
      avatar: avatar5,
      name: "Carlos Souza",
      location: "Conselheiro Lafaiete, MG",
      text: "A melhor internet que já tive. Uso para assistir filmes e jogar online sem problemas!",
      rating: 4.5
    },
    {
      avatar: avatar2,
      name: "Ana Paula",
      location: "Conselheiro Lafaiete, MG",
      text: "Super recomendo a VitaLink! O sinal nunca cai e a velocidade é ótima.",
      rating: 4.5
    },
    {
      avatar: avatar,
      name: "Pedro Almeida",
      location: "Conselheiro Lafaiete, MG",
      text: "Empresa confiável, entregam exatamente o que prometem. Não troco por nada!",
      rating: 5.0
    },
    {
      avatar: avatar3,
      name: "Lucas Santos",
      location: "Conselheiro Lafaiete, MG",
      text: "Melhor internet que já usei na região. Recomendo a todos!",
      rating: 5.0
    }
  ];

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 1 // Quantidade de slides ao clicar
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  // Função para renderizar estrelas com base na classificação
  const renderStars = (rating) => {
    const stars = [];
    const fullStars = Math.floor(rating);
    const hasHalfStar = rating - fullStars >= 0.5;

    for (let i = 0; i < fullStars; i++) {
      stars.push(<FaStar key={`full-${i}`} color="#f39c12" />);
    }

    if (hasHalfStar) {
      stars.push(<FaStarHalfAlt key="half" color="#f39c12" />);
    }

    // Preencher até 5 estrelas com estrelas vazias
    while (stars.length < 5) {
      stars.push(<FaRegStar key={`empty-${stars.length}`} color="#ccc" />);
    }

    return stars;
  };

  // Componentes de seta personalizada
  const CustomLeftArrow = ({ onClick }) => {
    return (
      <button
        className="custom-arrow left"
        onClick={onClick}
        aria-label="Anterior"
      >
        <FaArrowLeft />
      </button>
    );
  };

  const CustomRightArrow = ({ onClick }) => {
    return (
      <button
        className="custom-arrow right"
        onClick={onClick}
        aria-label="Próximo"
      >
        <FaArrowRight />
      </button>
    );
  };

  return (
    <section className="testimonials-section">
      <h2>O Que Nossos Clientes Estão Dizendo</h2>
      <p className="section-subtitle">
        Confira as opiniões de quem já usa nossos serviços e descubra por que somos a escolha certa em Conselheiro Lafaiete.
      </p>
      <div className="testimonials-section-secondary">
        <Carousel
          responsive={responsive}
          infinite={true}
          autoPlay={true}
          autoPlaySpeed={5000}
          keyBoardControl={true}
          showDots={true}
          renderDotsOutside={true}
          arrows={false}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
          containerClass="carousel-container"
          dotListClass="custom-dot-list-style"
          itemClass="carousel-item-padding-40-px"
        >
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial-card">
              <div className="testimonial-header">
                <img src={testimonial.avatar} alt={`Avatar de ${testimonial.name}`} className="testimonial-avatar" />
                <div className="testimonial-info">
                  <h4>{testimonial.name}</h4>
                  <p className="location">{testimonial.location}</p>
                  <div className="rating" aria-label={`Avaliação de ${testimonial.rating} estrelas`}>
                    {renderStars(testimonial.rating)}
                  </div>
                </div>
              </div>
              <p className="testimonial-text">"{testimonial.text}"</p>
            </div>
          ))}
        </Carousel>
      </div>
    </section>
  );
}

export default TestimonialsSection;
