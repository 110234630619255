// footer.js
import React from 'react';
import './Footer.css';
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';
import { scroller } from 'react-scroll';
import anatel from "../assets/anatel.png";

function Footer() {
  const navigate = useNavigate();
  const location = useLocation();

  const isSpecialPage = location.pathname !== '/' && location.pathname !== '/home';

  const handleLinkClick = (section) => {
    if (isSpecialPage) {
      navigate('/', { state: { scrollTo: section } });
    } else {
      scroller.scrollTo(section, {
        duration: 500,
        smooth: true,
        offset: -70,
      });
    }
  };

  const handleLogoClick = () => {
    handleLinkClick('home');
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h2 className="footer-logo" onClick={handleLogoClick}>
            VitaLink
          </h2>
          <p className="footer-description">
            A VitaLink é uma provedora de internet de alta velocidade que se dedica a conectar pessoas ao futuro da conectividade. Com uma infraestrutura moderna e suporte dedicado, oferecemos uma experiência de internet confiável, rápida e segura.
          </p>
        </div>
        <div className="footer-section links">
          <h3>Páginas</h3>
          <ul>
            <li>
              <button className="footer-link-button" onClick={() => handleLinkClick('home')}>
                Home
              </button>
            </li>
            <li>
              <button className="footer-link-button" onClick={() => handleLinkClick('about')}>
                Quem Somos
              </button>
            </li>
            <li>
              <button className="footer-link-button" onClick={() => handleLinkClick('plans')}>
                Planos
              </button>
            </li>
            <li>
              <a href="https://www.speedtest.net/pt" target="_blank" rel="noopener noreferrer" className="footer-external-link">
                Teste de Velocidade
              </a>
            </li>
            <li>
              <button className="footer-link-button" onClick={() => handleLinkClick('faq')}>
                Dúvidas
              </button>
            </li>
          </ul>
        </div>
        <div className="footer-section contact">
          <h3>Contatos</h3>
          <p>
            <span>Endereço: Rua da Conexão, 123, São Paulo, SP</span><br />
            <span>Telefone: (31) 99845-8433</span><br />
            <span>E-mail: contato@vitalink.com.br</span><br />
            <span>WhatsApp: (31) 99845-8433 (Atendimento 24/7)</span>
          </p>
        </div>
        <div className="footer-section social">
          <h3>Redes Sociais</h3>
          <div className="footer-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebookF /></a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          </div>
          <img src={anatel} alt="Logo Anatel" className="footer-anatel-logo" />
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 VitaLink Telecom. Todos os direitos reservados. Desenvolvido por AMDL Desenvolvimento</p>
      </div>
    </footer>
  );
}

export default Footer;
