import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './Form.css';
import contact from '../../assets/contact-image.jpg';
import logo from '../../assets/logo-contato.svg';

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const templateParams = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      message: formData.message,
    };

    emailjs.send('service_ykjaxxl', 'template_ps5wubg', templateParams, 'QZBZ6qWFSVghiQuxi')
      .then((response) => {
        alert('Formulário enviado com sucesso!');
        setIsLoading(false);
        setFormData({ name: '', email: '', phone: '', message: '' });
      }, (error) => {
        console.error('Erro:', error);
        alert('Erro ao enviar formulário.');
        setIsLoading(false);
      });
  };

  return (
    <section className="contact-section">
      <div className="contact-header">
        <h2>
          <img src={logo} className="contact-icon" /> Fale conosco
        </h2>
      </div>

      <div className="contact-content">
        <div className="contact-image">
          <img src={contact} alt="Equipe de atendimento" />
        </div>

        <div className="contact-form-container">
          <p>Entre em contato conosco para saber mais sobre nossos serviços e tirar suas dúvidas. Estamos prontos para ajudar!</p>

          <form className="contact-form" onSubmit={handleSubmit}>
            <label htmlFor="name">Nome:</label>
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="Digite seu nome"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <div>
                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Digite seu email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="phone">Telefone:</label>
                <input
                  type="text"
                  id="phone"
                  name="phone"
                  placeholder="Digite seu número"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>

            <label htmlFor="message">Mensagem:</label>
            <div className="form-group">
              <textarea
                name="message"
                placeholder="Digite sua mensagem"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-terms">
              <input type="checkbox" id="terms" required />
              <label htmlFor="terms">Eu aceito os termos</label>
            </div>

            <button type="submit" className="submit-button" disabled={isLoading}>
              {isLoading ? 'Enviando...' : 'Enviar'}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Form;
