import React, { useState } from 'react';
import './FAQSection.css';

function FAQSection() {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "Como posso verificar a cobertura em minha área?",
      answer: "Você pode verificar a cobertura diretamente em nosso site ou entrar em contato com o suporte para mais detalhes."
    },
    {
      question: "Quais velocidades de internet vocês oferecem?",
      answer: "Nossos planos variam de 50 Mbps a 1 Gbps, dependendo da sua localização e necessidades."
    },
    {
      question: "Vocês oferecem suporte técnico 24/7?",
      answer: "Sim, nosso suporte técnico está disponível 24/7 para resolver qualquer problema de conexão."
    },
    {
      question: "Como funciona a instalação do serviço?",
      answer: "Após a contratação, nossa equipe agenda a instalação em até 3 dias úteis para garantir uma conexão rápida e segura."
    },
    {
      question: "Os planos possuem limite de dados?",
      answer: "Nossos planos são ilimitados, permitindo que você navegue, assista e jogue sem restrições de dados."
    },
    {
      question: "Vocês oferecem descontos para pacotes anuais?",
      answer: "Sim, oferecemos descontos exclusivos para assinaturas anuais. Entre em contato para mais informações."
    },
    {
      question: "Como posso pagar minha fatura?",
      answer: "Aceitamos cartões de crédito, débito e boletos bancários. Você pode pagar através do nosso portal de clientes."
    },
    {
      question: "O que acontece em caso de problemas com a conexão?",
      answer: "Em caso de problemas, nossa equipe técnica realizará um diagnóstico remoto e, se necessário, uma visita técnica ao local."
    },
    {
      question: "Quais dispositivos são compatíveis com o nosso Wi-Fi?",
      answer: "Nossa conexão é compatível com a maioria dos dispositivos móveis, computadores e smart TVs modernos."
    },
  ];

  const toggleFAQ = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section id="faq" className="faq-section">
      <h2>Perguntas Frequentes</h2>
      <p className="section-subtitle">Esclareça suas dúvidas sobre os serviços de internet da VitaLink</p>
      <div className="faq">
        {faqs.map((faq, index) => (
          <div 
            key={index} 
            className={`faq-item ${activeIndex === index ? 'active' : ''}`} 
            onClick={() => toggleFAQ(index)}
          >
            <h3 className="faq-question">{faq.question}</h3>
            <div className="faq-answer">{activeIndex === index && <p>{faq.answer}</p>}</div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default FAQSection;
