import React from 'react';
import './Levels.css';

const Levels = () => {
  return (
    <div className="levels-card">
      <div className="levels-text">
        <h3>Pronto para Mudar de Nível?</h3>
        <p>Experimente a qualidade que você merece.<br />Entre em contato conosco e descubra o plano perfeito para você!</p>
      </div>
      <a
        href="https://wa.me/31998458433"
        className="levels-button"
        target="_blank"
        rel="noopener noreferrer"
      >
        FALE CONOSCO AGORA!
      </a>
    </div>
  );
};

export default Levels;
