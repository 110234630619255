import React, { useState, useEffect, useRef } from 'react';
import Chart from 'react-apexcharts';

const SolidityChart = ({ value = 75, color1 = '#00C360', color2 = '#B2FF8F' }) => {
     const [series, setSeries] = useState([0]); // Inicialmente vazio
     const chartRef = useRef(null); // Referência para o contêiner do gráfico
 
     const options = {
         chart: {
             toolbar: {
                 show: false,
             },
             events: {
                 mouseover: undefined,
             }
         },
         plotOptions: {
             radialBar: {
                 startAngle: -120,
                 endAngle: 120,
                 hollow: {
                     size: '70%',
                     background: 'transparent',
                 },
                 track: {
                     background: '#fff',
                     strokeWidth: '67%',
                     margin: 0,
                     dropShadow: {
                         enabled: true,
                         top: -3,
                         left: 0,
                         blur: 4,
                         opacity: 0.35,
                     },
                 },
                 dataLabels: {
                     show: false,
                     name: {
                         offsetY: 30,
                         formatter: () => (value >= 1000 ? 'Gbps' : 'Mbps'),
                         color: color1,
                         fontSize: '18px',
                     },
                     value: {
                         offsetY: -10,
                         formatter: () => (value >= 1000 ? (value / 1000).toFixed(0) : value),
                         color: color1,
                         fontSize: '36px',
                     },
                 },
             },
         },
         fill: {
             type: 'gradient',
             gradient: {
                 shade: 'light',
                 type: 'horizontal', // Tipo de gradiente: horizontal ou vertical conforme necessário
                 gradientToColors: [color2], // Cor final do gradiente
                 stops: [0, 100], // Posições do gradiente
                 colorStops: [
                     {
                         offset: 0,
                         color: color1,
                         opacity: 1
                     },
                     {
                         offset: 100,
                         color: color2,
                         opacity: 1
                     }
                 ]
             }
         },
         stroke: {
             lineCap: 'round',
         },
         labels: [],
         yaxis: {
             max: 1000,
         },
         animation: {
             enabled: true,
             easing: 'easeinout',
             speed: 800,
             animateGradually: {
                 enabled: true,
                 delay: 150
             },
             dynamicAnimation: {
                 enabled: true,
                 speed: 350
             }
         },
     };
 
     useEffect(() => {
         const observerOptions = {
             root: null,
             rootMargin: '0px',
             threshold: 0.3,
         };
 
         const observerCallback = (entries, observer) => {
             entries.forEach(entry => {
                 if (entry.isIntersecting) {
                     setSeries([value / 9]);
                     observer.unobserve(entry.target);
                 }
             });
         };
 
         const observer = new IntersectionObserver(observerCallback, observerOptions);
 
         if (chartRef.current) {
             observer.observe(chartRef.current);
         }
 
         return () => {
             if (chartRef.current) {
                 observer.unobserve(chartRef.current);
             }
         };
     }, [value]);
 
     return (
         <div 
             ref={chartRef} 
             style={{ maxWidth: '380px', margin: '10px auto 0px' }}
         >
             <Chart
                 options={options}
                 series={series}
                 type="radialBar"
                 height={250}
             />
         </div>
     );
 };
 
 export default SolidityChart;
 