// src/pages/Home.js
import React from 'react';
import HeroSection from '../components/home/HeroSection';
import FeaturesSection from '../components/home/FeaturesSection';
import AboutSection from '../components/home/AboutSection';
import PricingSection from '../components/home/PricingSection';
import Levels from '../components/home/Levels';
import TestimonialsSection from '../components/home/TestimonialsSection';
import FAQSection from '../components/home/FAQSection';

function Home() {
  return (
    <div>
      <HeroSection />
      <FeaturesSection />
      <AboutSection />
      <PricingSection />
      <Levels/>
      <TestimonialsSection />
      <FAQSection />
    </div>
  );
}

export default Home;
