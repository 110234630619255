import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import './HeroSection.css';
import gamerImage from '../../assets/hero-img.svg';

function HeroSection() {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact'); // Redireciona para a página de contato
  };

  return (
    <section id="home" className="hero-section">
      <div className="hero-text">
        <span className="service-badge">Provedor de Serviços de Internet</span>
        <h1>Conectando você ao futuro da Internet</h1>
        <p>Na VitaLink, oferecemos uma experiência de internet ultrarrápida e confiável. Esteja sempre conectado com o que há de mais moderno em tecnologia de fibra óptica.</p>
        <div className="hero-buttons">
          <ScrollLink 
            to="plans" 
            smooth={true} 
            duration={500} 
            className="btn-primary"
          >
            Ver Planos
          </ScrollLink>
          <RouterLink to="/contact" className="btn-secondary">
            Entre em Contato
          </RouterLink>
        </div>
      </div>
      <div className="hero-image">
        <img src={gamerImage} alt="Pessoa jogando videogame" />
      </div>
    </section>
  );
}

export default HeroSection;
