// FeaturesSection.jsx

import React from 'react';
import './FeaturesSection.css';

function FeaturesSection() {
  return (
    <section className="features-section">
      <h2>Por Que Escolher a VitaLink?</h2>
      <p className="features-intro">
        Na VitaLink, oferecemos uma conexão confiável e segura com tecnologia de ponta. Estamos prontos para conectar você ao futuro com um serviço de alta qualidade.
      </p>
      <div className="features-container">
        <div className="feature-item">
          <div className="feature-icon reliability-icon"></div>
          <h3>Confiabilidade e Estabilidade</h3>
          <p>Com nossa infraestrutura robusta, você se mantém conectado com estabilidade e confiabilidade, sem quedas.</p>
        </div>
        <div className="feature-item">
          <div className="feature-icon speed-icon"></div>
          <h3>Alta Velocidade</h3>
          <p>Navegação fluida com a tecnologia de fibra óptica da VitaLink, garantindo velocidade em todas as atividades online.</p>
        </div>
        <div className="feature-item">
          <div className="feature-icon security-icon"></div>
          <h3>Proteção e Privacidade</h3>
          <p>Oferecemos segurança avançada para proteger seus dados e garantir uma navegação privada e segura.</p>
        </div>
        <div className="feature-item">
          <div className="feature-icon support-icon"></div>
          <h3>Suporte 24/7</h3>
          <p>Nossa equipe está à disposição o tempo todo para oferecer suporte técnico e resolver qualquer problema.</p>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;
