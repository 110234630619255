import React from 'react';
import './AboutSection.css';
import about from '../../assets/about.svg'; // Imagem que contém as duas fotos juntas
import { FaEnvelope } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

function AboutSection() {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
  };

  return (
    <section id="about" className="about-section">
      <div className="about-content">
        {/* Container da imagem */}
        <div className="about-image-container">
          <img src={about} alt="Imagens combinadas" className="about-image" />
        </div>

        {/* Container do texto */}
        <div className="about-text">
          <h2 className="about-title">VitaLink</h2>
          <p className="subtitle">Conectamos pessoas ao futuro</p>

          {/* Estatísticas com novo design */}
          <div className="about-stats">
            <div className="stat-card">
              <h3>+5</h3>
              <span>Bairros</span>
            </div>
            <div className="stat-card">
              <h3>+100</h3>
              <span>Clientes</span>
            </div>
            <div className="stat-card">
              <h3>24</h3>
              <span>Horas de Suporte</span>
            </div>
          </div>

          {/* Descrição */}
          <div className="about-description">
            <p>
              Com mais de 100 clientes satisfeitos, a Vita Link oferece internet
              de alta velocidade com um foco inabalável em qualidade e
              confiabilidade. Somos uma empresa inovadora dedicada a levar
              conectividade de última geração para residências e empresas,
              proporcionando uma experiência de navegação que conecta você ao
              futuro.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSection;
