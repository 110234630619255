import React, { useState } from 'react';
import './whatsapp.css';
import whatsappIcon from '../assets/wpp.png';

const WhatsAppButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleBalloon = () => {
    setIsOpen(!isOpen);
  };

  const sendMessage = () => {
    window.open('https://wa.me/31998458433', '_blank');
  };

  return (
    <div className="whatsapp-container">
      {isOpen && (
        <div className="whatsapp-balloon">
          <p>Olá! Como posso ajudar você?</p>
          <button className="send-message-button" onClick={sendMessage}>
            Enviar Mensagem
          </button>
        </div>
      )}
      <button className="whatsapp-button" onClick={toggleBalloon}>
        <img src={whatsappIcon} alt="WhatsApp" />
      </button>
    </div>
  );
};

export default WhatsAppButton;
