import React from 'react';
import './PricingSection.css'; // Certifique-se de criar um arquivo de estilo se necessário

function StreamingButton({ logoSrc, altText, url }) {
     const handleClick = () => {
       window.open(url, '_blank'); // Abre o site em uma nova aba
     };

     return (
       <button className="streaming-button" onClick={handleClick}>
         <img src={logoSrc} alt={altText} className="streaming-logo" />
       </button>
     );
   }
   
   export default StreamingButton;